import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';

import { compose } from '../../utils';
import { saving } from '../../redux/submission/selectors';
import { list, defaultLang, lang } from '../../redux/lang/selectors';

import SubmissionForm from './SubmissionForm';

const reduxConect = connect(
  createStructuredSelector({
    saving,
    langList: list,
    defaultLang,
    selectedLang: lang,
  }),
);

const formConect = reduxForm({
  form: 'userSubmission',
  enableReinitialize: true,
});

export { SubmissionForm };

export default compose(reduxConect, formConect)(SubmissionForm);
