import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm, formValueSelector } from 'redux-form';

import { compose } from '../../utils';
import { listAdmin } from '../../redux/category-type/selectors';
import { saving } from '../../redux/materials-category-type/selectors';
import { list, defaultLang } from '../../redux/lang/selectors';

import MaterialsCategoryTypeForm from './MaterialsCategoryTypeForm';

const formConnect = reduxForm({
  form: 'materialsCategoryType',
  enableReinitialize: true,
});

const values = formValueSelector('materialsCategoryType');

const reduxConnect = connect(
  createStructuredSelector({
    saving,
    langList: list,
    categoryTypes: listAdmin,
    defaultLang,
    categoryType: state => values(state, 'materialsCategoryType'),
  }),
);

export default compose(reduxConnect, formConnect)(MaterialsCategoryTypeForm);
