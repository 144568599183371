import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ArticlePage from './ArticlePage';

import { lang, defaultLang } from '../../redux/lang/selectors';
import { item } from '../../redux/article/selectors';
import { getItem } from '../../redux/article/actions';
import { showRelated } from '../../redux/settings/selectors';

const selector = createStructuredSelector({
  lang,
  defaultLang,
  item,
  showRelated,
});

const actions = { getItem };

export { ArticlePage };

export default connect(selector, actions)(ArticlePage);
