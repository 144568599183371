import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

import CustomEmailPreview from './CustomEmailPreview';

const values = formValueSelector('customEmail');

const selector = createStructuredSelector({
  values: state =>
    values(
      state,
      'subject',
      'headerTitle',
      'date',
      'hDate',
      'sponsor',
      'sponsorText',
      'sponsorButton',
      'sponsorLink',
      'headline',
      'title',
      'author',
      'text',
      'lang',
      'ctaButton',
      'ctaLink',
      'footer',
      'footerTitle',
      'footerText',
    ),
});

const actions = {};

export default connect(selector, actions)(CustomEmailPreview);
