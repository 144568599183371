import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm, formValueSelector } from 'redux-form';

import { compose } from '../../utils';
import { saving, listAdmin } from '../../redux/category-type/selectors';
import { list, defaultLang } from '../../redux/lang/selectors';

import CategoryTypeForm from './CategoryTypeForm';

const formConnect = reduxForm({
  form: 'categoryType',
  enableReinitialize: true,
});

const values = formValueSelector('categoryType');

const reduxConnect = connect(
  createStructuredSelector({
    saving,
    langList: list,
    categoryTypes: listAdmin,
    defaultLang,
    langValues: state => values(state, 'lang'),
  }),
);

export default compose(reduxConnect, formConnect)(CategoryTypeForm);
