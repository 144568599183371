import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import SubscribeButton from './SubscribeButton';

import { lang } from '../../redux/lang/selectors';

const selector = createStructuredSelector({
  lang,
});

export default connect(selector, null)(SubscribeButton);
