import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import LocalizedLink from '../../../LocalizedLink';

import {
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';

import { IMG_URL } from '../../../../constants/files';

import classes from './Banner.module.scss';
import Loader from '../../../Loader';

const bannerInterval = 6000;
let timer;

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.animating = false;
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  componentDidMount() {
    this.props.getBanners();
    this.startSlide();
  }

  componentWillUnmount() {
    if (timer) {
      clearInterval(timer);
    }
  }

  startSlide = () => {
    timer = setInterval(this.next, bannerInterval);
  };

  resetInterval = () => {
    if (timer) {
      clearInterval(timer);
    }
    timer = setInterval(this.next, bannerInterval);
  };

  handleMouseMove = e => {
    _.throttle(this.resetInterval, 400, { maxWait: 500 })();
  };

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const { banners } = this.props;
    let { activeIndex } = this.state;
    this.setState({
      activeIndex: banners.length < ++activeIndex + 1 ? 0 : activeIndex,
    });
  }

  prev(e) {
    if (this.animating) return;
    const { banners } = this.props;
    let { activeIndex } = this.state;
    this.setState({
      activeIndex: 0 > --activeIndex ? banners.length - 1 : activeIndex,
    });
  }

  handleNextClick = () => {
    if (timer) {
      clearInterval(timer);
    }
    this.next();
  };

  handlePrevClick = () => {
    if (timer) {
      clearInterval(timer);
    }
    this.prev();
  };

  goToIndex(activeIndex) {
    if (timer) {
      clearInterval(timer);
    }
    if (this.animating) return;
    this.setState({ activeIndex });
  }

  renderItem(item) {
    const { lang, defaultLang } = this.props;
    const langData = item.lang[lang] || item.lang[defaultLang];
    const isHE = lang === 'he';

    return (
      <CarouselItem
        onExiting={this.onExiting}
        onExited={this.onExited}
        key={`banner-${item.id}`}
        className={classes.bannerItem}
      >
        <Row className="align-items-center">
          <Col
            lg="5"
            className={classnames('pl-md-8 pr-md-0 pl-5 pr-5 text-center', {
              'text-md-left': !isHE,
              'text-md-right': isHE,
            })}
            dir={`${isHE ? 'rtl' : 'ltr'}`}
          >
            <div className="pr-md-5 mb-4 mb-lg-0">
              <h2
                className={`display-2 text-white font-weight-bold mb-4 ${classes.bannerTitle}`}
              >
                {langData.title}
              </h2>
              <p className="text-white mb-4">{langData.description}</p>
              <div className="mt-5">
                {/^http/i.test(item.btnLink) ? (
                  <a
                    className="btn btn-lg btn-secondary my-2"
                    href={item.btnLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {langData.btnLabel}
                  </a>
                ) : (
                  <LocalizedLink
                    className="btn btn-lg btn-secondary my-2"
                    to={item.btnLink}
                  >
                    {langData.btnLabel}
                  </LocalizedLink>
                )}
              </div>
            </div>
          </Col>
          <Col lg="7" className="d-none d-lg-block">
            <div
              className={classes.image}
              style={{ backgroundImage: `url(${IMG_URL}${item.img})` }}
            />
          </Col>
        </Row>
        <Row className={`text-center d-none d-lg-flex ${classes.caption}`}>
          {/^http/i.test(item.captionLink) ? (
            <a
              className="col-9"
              href={item.captionLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="display-4 text-default">{langData.caption}</span>
            </a>
          ) : (
            <LocalizedLink to={item.captionLink} className="col-9">
              <span className="display-4 text-default">{langData.caption}</span>
            </LocalizedLink>
          )}

          {/^http/i.test(item.captionLink) ? (
            <a
              className="col-3 display-2 text-white font-weight-300 bg-default"
              href={item.captionLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon="chevron-right" />
            </a>
          ) : (
            <LocalizedLink
              to={item.captionLink}
              className="col-3 display-2 text-white font-weight-300 bg-default"
            >
              <FontAwesomeIcon icon="chevron-right" />
            </LocalizedLink>
          )}
        </Row>
      </CarouselItem>
    );
  }

  render() {
    const { banners, loading, lang } = this.props;
    const { activeIndex } = this.state;
    if (!banners.length) return null;

    const current = activeIndex + 1;

    return (
      <div className="header-body" onMouseMove={this.handleMouseMove}>
        {loading && <Loader className="py-9 my-7" size="display-1" />}
        <Carousel
          next={this.handleNextClick}
          previous={this.handlePrevClick}
          activeIndex={activeIndex}
        >
          {banners.map(this.renderItem)}
          <div className={`${classes.controls}`} lang={lang}>
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={this.prev}
            />
            <CarouselIndicators
              items={banners}
              activeIndex={activeIndex}
              onClickHandler={this.goToIndex}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={this.next}
            />
            <span className={`h3 text-white ${classes.numberIndicator}`}>
              {current > 9 ? current : `0${current}`}
            </span>
            <span className={`h3 text-white ${classes.numberIndicator}`}>
              {banners.length > 9 ? banners.length : `0${banners.length}`}
            </span>
          </div>
        </Carousel>
      </div>
    );
  }
}

export default Banner;
