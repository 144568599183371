import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Audios from './Audios';

import { recent } from '../../../../redux/audio/selectors';
import { getRecent, searchHomePage } from '../../../../redux/audio/actions';

const selector = createStructuredSelector({
  recent,
});

export default connect(selector, { getRecent, onSearch: searchHomePage })(
  Audios,
);
