import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Documents from './Documents';

import { recent } from '../../../../redux/document/selectors';
import { getRecent, searchHomePage } from '../../../../redux/document/actions';

const selector = createStructuredSelector({
  recent,
});

export default connect(selector, { getRecent, onSearch: searchHomePage })(
  Documents,
);
