import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import DocumentPage from './DocumentPage';

import { lang, defaultLang } from '../../redux/lang/selectors';
import { item } from '../../redux/document/selectors';
import { getItem } from '../../redux/document/actions';
import { add } from '../../redux/submission/actions';
import { submissionModalOpened } from '../../redux/submission/selectors';
import { showRelated } from '../../redux/settings/selectors';

const selector = createStructuredSelector({
  lang,
  item,
  defaultLang,
  submissionModalOpened,
  showRelated,
});

const actions = { getItem, createSubmission: add };

export { DocumentPage };

export default connect(selector, actions)(DocumentPage);
