import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';

import { compose } from '../../utils';
import { saving } from '../../redux/submission/selectors';
import { list, defaultLang } from '../../redux/lang/selectors';

import SubmissionAdminForm from './SubmissionAdminForm';

const reduxConect = connect(
  createStructuredSelector({
    saving,
    langList: list,
    defaultLang,
  }),
);

const formConect = reduxForm({
  form: 'userAdminSubmission',
  enableReinitialize: true,
});

export { SubmissionAdminForm };

export default compose(reduxConect, formConect)(SubmissionAdminForm);
