import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm, formValueSelector } from 'redux-form';

import { compose } from '../../utils';
import { saving } from '../../redux/document/selectors';
import { list, defaultLang } from '../../redux/lang/selectors';
import { getListAdmin as getCategoriesRelations } from '../../redux/materials-category-type/actions';
import { listAdmin as categoriesRelations } from '../../redux/materials-category-type/selectors';
import { getListAdmin as getCategoryTypesList } from '../../redux/category-type/actions';
import { listAdmin as categoryTypes } from '../../redux/category-type/selectors';
import { getListByDoctype } from '../../redux/category/actions';

import DocumentForm from './DocumentForm';

const formConnect = reduxForm({
  form: 'document',
  enableReinitialize: true,
});

const values = formValueSelector('document');

const reduxConnect = connect(
  createStructuredSelector({
    saving,
    langList: list,
    defaultLang,
    langValues: state => values(state, 'lang'),
    relatedValues: state => values(state, 'related'),
    langData: state => values(state, 'lang'),
    categoriesRelations,
    categoryTypes,
  }),
  {
    getCategoriesRelations,
    getCategoryTypesList,
    getListByDoctype,
  },
);

export default compose(reduxConnect, formConnect)(DocumentForm);
