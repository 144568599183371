import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  update,
  add,
} from '../../../../../redux/materials-category-type/actions';
import { loading } from '../../../../../redux/materials-category-type/selectors';
import RelationModal from './RelationModal';

const selector = createStructuredSelector({
  loading,
});

const actions = {
  add,
  update,
};

export default connect(selector, actions)(RelationModal);
