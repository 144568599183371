import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  getItemAdmin,
  getTypes,
  add,
  update,
  remove,
  setFile,
} from '../../../redux/audio/actions';
import {
  loading,
  saving,
  itemAdmin,
  types,
} from '../../../redux/audio/selectors';
import { list, defaultLang } from '../../../redux/lang/selectors';
import AudioItemPage from './AudioItemPage';

const selector = createStructuredSelector({
  loading,
  saving,
  audioItem: itemAdmin,
  langList: list,
  types,
  defaultLang,
});

const actions = {
  fetch: getItemAdmin,
  getTypes,
  add,
  update,
  remove,
  setFile,
};

export { AudioItemPage };

export default connect(selector, actions)(AudioItemPage);
