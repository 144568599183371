import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

import EmailPreview from './EmailPreview';

const values = formValueSelector('sendSubscription');

const selector = createStructuredSelector({
  values: state =>
    values(
      state,
      'bwId',
      'subject',
      'subjectCustom',
      'date',
      'hDate',
      'headline',
      'title',
      'author',
      'text',
      'lang',
    ),
});

const actions = {};

export default connect(selector, actions)(EmailPreview);
