import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';

import Autosuggest from 'react-autosuggest';
import {
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import { typesIcons, bwLabels, types } from '../../constants/document';
import { TRANSLATIONS } from '../../constants/translations';
import { ROUTES } from '../../constants/routes';
import { getSearchDocumentLink, getLabelFromLang } from '../../utils';
import LocalizedLink from '../LocalizedLink';

const DEBOUNCE_TIME = 1000;

class SearchFormTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
      lang: props.lang,
      fromProps: {
        results: [],
      },
    };

    this.onSearch = _.debounce(
      e => this.onSuggestionsFetchRequested(e),
      DEBOUNCE_TIME,
    );
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { results } = nextProps;
    const newState = {
      fromProps: {
        results: state.fromProps.results,
      },
    };
    if (state.fromProps.results !== results) {
      newState.suggestions = results;
      newState.fromProps.results = results;
    }

    return newState;
  }

  onSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();
    const { history, lang, searchedStr } = this.props;
    let link;
    if (suggestion.Document) {
      link = getSearchDocumentLink(suggestion.Document, lang);
    }

    if (suggestion.BusinessWeekly) {
      link = `/${lang}${ROUTES.BUSINESS_WEEKLY_ITEM_PARAM}${suggestion.BusinessWeekly.id}`;
    }

    if (suggestion.categoryId) {
      link = getSearchDocumentLink(suggestion, lang);
    }

    if (suggestion.showMore) {
      link = `${suggestion.link}?s=${searchedStr}`;
    }

    history.push(link);
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onSubmit = event => {
    event.preventDefault();
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { search, searchedStr, loading } = this.props;
    if (loading) return;

    if (searchedStr && searchedStr.toLowerCase() === value.toLowerCase())
      return;
    const searching = value.trim().toLowerCase();
    const inputLength = searching.length;
    if (inputLength && inputLength > 1)
      search({ searching, isTopResults: true, reset: true });
  };

  onSuggestionsClearRequested = () => {
    if (!this.state.value) this.setState({ suggestions: [] });
  };

  getSuggestionValue(suggestion) {
    return this.props.searchedStr;
  }

  renderSuggestion(suggestion) {
    const { lang } = this.props;
    if (suggestion.Document) {
      return (
        <div>
          <span className="h5 mr-2 text-muted">
            {typesIcons[suggestion.Document.type]}
          </span>
          {suggestion.Document.type === types.BUSINESS_WEEKLY && (
            <span className="h5 mr-2 text-muted">{bwLabels[lang]}</span>
          )}
          <span className="my-0 h4">{suggestion.title}</span>
        </div>
      );
    }

    if (suggestion.BusinessWeekly) {
      return (
        <div>
          <span className="h5 mr-2 text-muted">
            {typesIcons.BUSINESS_WEEKLY}
          </span>
          <span className="h5 mr-2 text-muted">{bwLabels[lang]}</span>
          <span className="my-0 h4">{suggestion.title}</span>
          {suggestion.number && (
            <span className="my-0 h4 text-muted"> #{suggestion.number}</span>
          )}
        </div>
      );
    }

    if (suggestion.categoryId) {
      return (
        <div>
          <span className="h5 mr-2 text-muted">
            {typesIcons[suggestion.type]}
          </span>
          <span className="my-0 h4">{suggestion.lang[lang].title}</span>
          <div className="text-muted text-sm">
            found by <span className="text-default">Category</span>:&nbsp;
            {suggestion.categoryLabel}
          </div>
        </div>
      );
    }

    if (suggestion.showMore) {
      return (
        <div>
          <p className="my-0 h4 text-info">Show more</p>
        </div>
      );
    }
  }

  render() {
    const { loading, reset, lang } = this.props;
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: getLabelFromLang(TRANSLATIONS.SEARCH_PLACEHOLDER, lang),
      value,
      onChange: this.onChange,
      type: 'text',
      className: 'form-control',
    };

    return (
      <Form
        className={classnames(
          'navbar-search form-inline',
          { 'navbar-search-light': this.props.theme === 'dark' },
          { 'navbar-search-dark': this.props.theme === 'light' },
        )}
        onSubmit={this.onSubmit}
      >
        <FormGroup className="mb-0">
          <InputGroup className="input-group-alternative input-group-merge">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                {loading ? (
                  <i className="text-default fas fa-spinner fa-pulse" />
                ) : (
                  <i className="fas fa-search" />
                )}
              </InputGroupText>
            </InputGroupAddon>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSearch}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={this.getSuggestionValue}
              onSuggestionSelected={this.onSuggestionSelected}
              renderSuggestion={this.renderSuggestion}
              focusInputOnSuggestionClick={false}
              inputProps={inputProps}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                {loading ? (
                  <i className="text-default fas fa-spinner fa-pulse" />
                ) : (
                  <i className="fas fa-search" />
                )}
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>

          <LocalizedLink
            className="btn navbar-search-btn"
            to={ROUTES.SEARCH_PAGE_ADVANCED}
            onClick={reset}
          >
            {getLabelFromLang(TRANSLATIONS.ADVANCED_SEARCH, lang)}
          </LocalizedLink>
        </FormGroup>

        <button
          aria-label="Close"
          className="close"
          type="button"
          onClick={this.props.closeSearch}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </Form>
    );
  }
}

export default withRouter(SearchFormTop);
