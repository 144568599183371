import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import DocumentsPage from './DocumentsPage';

import { lang } from '../../redux/lang/selectors';
import { list, allCount, loading } from '../../redux/document/selectors';
import { getList } from '../../redux/document/actions';
const selector = createStructuredSelector({
  list,
  allCount,
  lang,
  loading,
});

const actions = { getList };

export { DocumentsPage };

export default connect(selector, actions)(DocumentsPage);
