import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  getListAdmin as getTypeListAdmin,
  remove,
  getItemAdmin,
} from '../../../../../redux/category-type/actions';
import {
  loading as typeLoading,
  listAdmin as typeListAdmin,
  categoryTypeModalOpened,
  itemAdmin,
} from '../../../../../redux/category-type/selectors';
import { list, defaultLang } from '../../../../../redux/lang/selectors';
import Types from './Types';

const selector = createStructuredSelector({
  typeLoading,
  typeList: typeListAdmin,
  langList: list,
  defaultLang,
  categoryTypeModalOpened,
  itemAdmin,
});

const actions = {
  getTypeListAdmin,
  remove,
  getItemAdmin,
};

export default connect(selector, actions)(Types);
