import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  getItemAdmin,
  getListAdmin,
  add,
  update,
  remove,
  setFile,
  getRecent,
} from '../../../redux/business-weekly/actions';
import {
  loading,
  saving,
  itemAdmin,
  listAdmin,
  recent,
} from '../../../redux/business-weekly/selectors';
import { list, defaultLang } from '../../../redux/lang/selectors';
import BusinessWeeklyItemPage from './BusinessWeeklyItemPage';

const selector = createStructuredSelector({
  loading,
  saving,
  bwItem: itemAdmin,
  listAdmin,
  langList: list,
  recent,
  defaultLang,
});

const actions = {
  fetch: getItemAdmin,
  getListAdmin,
  add,
  update,
  remove,
  setFile,
  getRecent,
};

export { BusinessWeeklyItemPage };

export default connect(selector, actions)(BusinessWeeklyItemPage);
