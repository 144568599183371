import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  getListAdmin,
  changeStatus,
  remove,
} from '../../../redux/submission/actions';
import { loading, listAdmin } from '../../../redux/submission/selectors';
import { list, defaultLang } from '../../../redux/lang/selectors';
import SubmissionListPage from './SubmissionListPage';

const selector = createStructuredSelector({
  loading,
  list: listAdmin,
  langList: list,
  defaultLang,
});

const actions = {
  getListAdmin,
  changeStatus,
  remove,
};

export { SubmissionListPage };

export default connect(selector, actions)(SubmissionListPage);
