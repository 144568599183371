import React, { Component } from 'react';

import {
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Card,
  CardBody,
} from 'reactstrap';
import DatePicker from '../DatePicker/DatePicker';

import { Field } from 'redux-form';
import InputRedux from '../Input/InputRedux';
import PropTypes from 'prop-types';
import moment from 'moment';

class CustomEmailForm extends Component {
  static propTypes = {
    handleSend: PropTypes.func.isRequired,
    handleSchedule: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.initData();
  }

  initData() {
    const { data, listsPage, change, langList } = this.props;
    const emailData = (data && data.emailData) || '';

    const initLang =
      (langList && langList[0] && langList[0].identifier) || 'en';

    const initMailingList =
      (listsPage && listsPage[0] && listsPage[0].address) || '';
    const initMailingName =
      (listsPage && listsPage[0] && listsPage[0].name) || '';

    const initEmailDate = moment(new Date())
      .utc()
      .minute(0)
      .format();

    const initFiles =
      data &&
      data.Files &&
      data.Files.map(file => ({ ...file, name: file.meta.originalFilename }));

    change('ceId', (data && data.id) || '');
    change('subject', (emailData && emailData.subject) || '');
    change('headerTitle', (emailData && emailData.headerTitle) || '');
    change('date', (emailData && emailData.date) || '');
    change('hDate', (emailData && emailData.hDate) || '');
    change('sponsor', (emailData && emailData.sponsor === 'true') || false);
    change('sponsorText', (emailData && emailData.sponsorText) || '');
    change('sponsorButton', (emailData && emailData.sponsorButton) || '');
    change('sponsorLink', (emailData && emailData.sponsorLink) || '');
    change('headline', (emailData && emailData.headline) || '');
    change('title', (emailData && emailData.title) || '');
    change('author', (emailData && emailData.author) || '');
    change('text', (emailData && emailData.text) || '');
    change('lang', (emailData && emailData.lang) || initLang);
    change('ctaButton', (emailData && emailData.ctaButton) || '');
    change('ctaLink', (emailData && emailData.ctaLink) || '');
    change('footer', (emailData && emailData.footer) || false);
    change('footerTitle', (emailData && emailData.footerTitle) || '');
    change('footerText', (emailData && emailData.footerText) || '');
    change(
      'mailingList',
      (emailData && emailData.mailingList) || initMailingList,
    );
    change(
      'mailingName',
      (emailData && emailData.mailingName) || initMailingName,
    );
    change('emailDate', (emailData && emailData.emailDate) || initEmailDate);
    change('filesToSend', initFiles || []);
  }

  handleChangeEmailDate = value => {
    const { change } = this.props;

    change(
      `emailDate`,
      value
        .utc()
        .minute(0)
        .format(),
    );
  };

  mailingChanged = (e, value) => {
    const { change, listsPage } = this.props;
    const matched = listsPage.filter(item => item.address === value)[0];
    change(
      `mailingName`,
      matched.name || (listsPage && listsPage[0] && listsPage[0].name) || '',
    );
  };

  onFilesChange = (e, value) => {
    const { filesToSend, change } = this.props;
    const incomeFiles = Array.isArray(value) ? value : Array.from(value);

    const ifExist = () =>
      incomeFiles.filter(
        fileInc => !filesToSend.some(file => fileInc.name === file.name),
      );

    change('filesToSend', filesToSend.concat(ifExist()));
  };

  deleteFile(name) {
    const { filesToSend, change } = this.props;
    change(
      'filesToSend',
      filesToSend.filter(file => file.name !== name),
    );
  }

  render() {
    const {
      isSponsor,
      isFooter,
      langList,
      selectedLang,
      emailDate,
      listsPage,
      loading,
      sending,
      sendObj,
      handleSend,
      handleSchedule,
      data,
      remove,
      filesToSend,
    } = this.props;

    const isHE = selectedLang === 'he';
    return (
      <Form role="form">
        <p className="display-4">
          {data && data.id && `#${data.id}`}
          {data.emailedAt && (
            <span className="h5 text-warning">
              &nbsp;(This email has already been sent)
            </span>
          )}
          {data.emailDate && !data.emailedAt && (
            <span className="h5  text-warning">
              &nbsp;Emails scheduled for {moment(data.emailDate).calendar()}
            </span>
          )}
        </p>
        <Row>
          <Col>
            <FormGroup className="mb-3">
              <label className="form-control-label">Mailing list&nbsp;</label>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-world-2" />
                  </InputGroupText>
                </InputGroupAddon>

                <Field
                  name="mailingList"
                  type="select"
                  disabled={sending || loading}
                  onChange={this.mailingChanged}
                  required
                  options={
                    listsPage &&
                    listsPage.map(l => ({
                      label: `${l.name} - members(${l.members_count}) - address(${l.address})`,
                      value: l.address,
                    }))
                  }
                  component={InputRedux}
                  dir={`${isHE ? 'rtl' : 'ltr'}`}
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className="mb-3">
              <label className="form-control-label">Language&nbsp;</label>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-world-2" />
                  </InputGroupText>
                </InputGroupAddon>
                <Field
                  name="lang"
                  type="select"
                  disabled={sending || loading}
                  required
                  options={langList.map(l => ({
                    label: l.fullName,
                    value: l.identifier,
                  }))}
                  component={InputRedux}
                  dir={`${isHE ? 'rtl' : 'ltr'}`}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup className="mb-3">
              <label className="form-control-label">Email subject&nbsp;</label>
              <Field
                name="subject"
                type="text"
                disabled={sending || loading}
                required
                component={InputRedux}
                dir={`${isHE ? 'rtl' : 'ltr'}`}
              />
            </FormGroup>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label">Header title&nbsp;</label>
              <Field
                name="headerTitle"
                type="text"
                disabled={sending || loading}
                required
                component={InputRedux}
                dir={`${isHE ? 'rtl' : 'ltr'}`}
              />
            </FormGroup>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label">
                Enter English date&nbsp;
              </label>
              <Field
                name="date"
                type="text"
                disabled={sending || loading}
                required
                component={InputRedux}
                dir={`${isHE ? 'rtl' : 'ltr'}`}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label className="form-control-label">
                Enter Hebrew date&nbsp;
              </label>
              <Field
                name="hDate"
                type="text"
                disabled={sending || loading}
                required
                component={InputRedux}
                dir={`${isHE ? 'rtl' : 'ltr'}`}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label">Show Sponsor Section</label>
              <Field
                name="sponsor"
                type="checkbox"
                disabled={sending || loading}
                component={InputRedux}
              />
            </FormGroup>
          </Col>
        </Row>
        {isSponsor ? (
          <div>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label">Sponsor text</label>
                  <Field
                    name="sponsorText"
                    type="text"
                    disabled={sending || loading}
                    component={InputRedux}
                    dir={`${isHE ? 'rtl' : 'ltr'}`}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <label className="form-control-label">
                    Sponsor button text
                  </label>
                  <Field
                    name="sponsorButton"
                    type="text"
                    disabled={sending || loading}
                    component={InputRedux}
                    dir={`${isHE ? 'rtl' : 'ltr'}`}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label">Sponsor link</label>
                  <Field
                    name="sponsorLink"
                    type="text"
                    disabled={sending || loading}
                    component={InputRedux}
                    dir={`${isHE ? 'rtl' : 'ltr'}`}
                  />
                </FormGroup>
              </Col>
              <Col />
            </Row>
          </div>
        ) : null}
        <FormGroup>
          <label className="form-control-label">Headline</label>
          <Field
            name="headline"
            type="textarea"
            required
            disabled={sending || loading}
            rows={2}
            component={InputRedux}
            dir={`${isHE ? 'rtl' : 'ltr'}`}
          />
        </FormGroup>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label">Title</label>
              <Field
                name="title"
                type="text"
                required
                disabled={sending || loading}
                component={InputRedux}
                dir={`${isHE ? 'rtl' : 'ltr'}`}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label className="form-control-label">Author</label>
              <Field
                name="author"
                type="text"
                required
                disabled={sending || loading}
                component={InputRedux}
                dir={`${isHE ? 'rtl' : 'ltr'}`}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <label className="form-control-label">Text</label>
          <Field
            name="text"
            type="quill"
            disabled={sending || loading}
            required
            rows={20}
            component={InputRedux}
            dir={`${isHE ? 'rtl' : 'ltr'}`}
            index={1}
          />
        </FormGroup>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label">CTA button text</label>
              <Field
                name="ctaButton"
                type="text"
                required
                disabled={sending || loading}
                component={InputRedux}
                dir={`${isHE ? 'rtl' : 'ltr'}`}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <label className="form-control-label">CTA button link</label>
              <Field
                name="ctaLink"
                type="text"
                required
                disabled={sending || loading}
                component={InputRedux}
                dir={`${isHE ? 'rtl' : 'ltr'}`}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label">Show Footer Section</label>
              <Field
                name="footer"
                type="checkbox"
                disabled={sending || loading}
                component={InputRedux}
              />
            </FormGroup>
          </Col>
        </Row>
        {isFooter && (
          <div>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label">Footer title</label>
                  <Field
                    name="footerTitle"
                    type="text"
                    disabled={sending || loading}
                    component={InputRedux}
                    dir={`${isHE ? 'rtl' : 'ltr'}`}
                  />
                </FormGroup>
              </Col>
              <Col />
            </Row>
            <FormGroup>
              <label className="form-control-label">Footer text</label>
              <Field
                name="footerText"
                type="quill"
                disabled={sending || loading}
                required
                rows={20}
                component={InputRedux}
                dir={`${isHE ? 'rtl' : 'ltr'}`}
                index={2}
              />
            </FormGroup>
          </div>
        )}
        <Row>
          <Col>
            <FormGroup className="mb-3">
              <label className="form-control-label">Upload files&nbsp;</label>
              <Field
                name="files"
                type="file"
                multiple
                disabled={sending || loading}
                onChange={this.onFilesChange}
                component={InputRedux}
              />
            </FormGroup>
          </Col>
        </Row>
        {filesToSend &&
          filesToSend.map(file => (
            <Row key={file.name}>
              <Col>
                <Card>
                  <CardBody className="d-flex align-items-center justify-content-between">
                    {file.name}
                    <Button
                      className="btn btn-icon btn-danger btn-sm"
                      type="button"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Delete file"
                      onClick={e => this.deleteFile(file.name)}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-fat-remove" />
                      </span>
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ))}
        <Row>
          <Col>
            <FormGroup>
              <label className="form-control-label">Schedule email date</label>

              <DatePicker
                onChange={this.handleChangeEmailDate}
                value={emailDate}
              />
            </FormGroup>
          </Col>
          <Col />
        </Row>
        <Button
          disabled={sending}
          onClick={() => handleSchedule(sendObj)}
          color="primary"
          className={
            isHE ? 'btn btn-icon mt-5 isHeButton' : 'btn btn-icon mt-5'
          }
        >
          <span className="btn-inner--icon">
            {sending ? (
              <i className="text-white fas fa-spinner fa-pulse" />
            ) : (
              <i className="ni ni-calendar-grid-58" />
            )}
          </span>
          <span className="btn-inner--text">
            {data && data.emailDate ? 'Reschedule' : 'Schedule'}
          </span>
        </Button>
        <Button
          disabled={sending}
          onClick={() => handleSend(sendObj)}
          color="info"
          className={
            isHE ? 'btn btn-icon mt-5 isHeButton' : 'btn btn-icon mt-5'
          }
        >
          <span className="btn-inner--icon">
            {sending ? (
              <i className="text-white fas fa-spinner fa-pulse" />
            ) : (
              <i className="ni ni-send" />
            )}
          </span>
          <span className="btn-inner--text">
            {data && data.emailedAt ? 'Resend' : 'Send now'}
          </span>
        </Button>
        {data && data.id ? (
          <Button
            disabled={sending}
            onClick={e => remove(e, data.id)}
            color="danger"
            className={
              isHE ? 'btn btn-danger mt-5 isHeButton' : 'btn btn-danger mt-5'
            }
          >
            <span className="btn-inner--icon">
              {sending ? (
                <i className="text-white fas fa-spinner fa-pulse" />
              ) : (
                <i className="ni ni-fat-remove" />
              )}
            </span>
            <span className="btn-inner--text"> Delete</span>
          </Button>
        ) : null}
      </Form>
    );
  }
}

export default CustomEmailForm;
