import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// import { recent } from '../../../../redux/business-weekly/selectors';
import { defaultLang } from '../../../../redux/lang/selectors';
import CustomEmailModal from './CustomEmailModal';

const selector = createStructuredSelector({
  // recent,
  defaultLang,
});

const actions = {};

export default connect(selector, actions)(CustomEmailModal);
