import React from 'react';

import ContactForm from './ContactForm';
import Hr from '../Hr';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';

import { TRANSLATIONS } from '../../constants/translations';

import classes from './ContactUs.module.scss';
import { EMAIL_CONTACT, PHONE, FAX } from '../../constants/contactInfo';

class ContactUs extends React.Component {
  render() {
    return (
      <section className="py-md-6 py-3 position-relative">
        <Container>
          <Row>
            <Col md={6} lg={7} className={classes.formContainer}>
              <Hr color="default" />
              <h2 className="display-3">How can we help?</h2>
              <p className="mb-4 pr-lg-8 font-weight-normal">
                {TRANSLATIONS.CONTACT_US_TEXT.EN}
              </p>
              <ContactForm />
            </Col>
          </Row>
        </Container>
        <Row
          noGutters
          className={`${classes.fluidContainer} justify-content-end w-100 top-6 left-0`}
        >
          <Col
            md={6}
            lg={5}
            className={`p-4 bg-gradient-info ${classes.gradientSection}`}
          >
            <div className={`position-relative ${classes.fluidData}`}>
              <h2 className="display-3 mb-4 text-white">Contact us</h2>
              <Hr color="white" />
              <Row className="mt-4 align-items-center text-center text-sm-left">
                <Col sm={3}>
                  <Card className="text-center mb-sm-0 mb-2 d-inline-block">
                    <CardBody
                      className={`p-3 display-3 btn-inner--icon text-primary ${classes.icon}`}
                    >
                      <i className="ni ni-pin-3" />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm={9}>
                  <p className="h3 text-white mb-1">1937 Ocean Avenue,</p>
                  <p className="h3 text-white mb-sm-0">Brooklyn N.Y. 11230</p>
                </Col>
              </Row>
              <Row className="mt-4 align-items-center text-center text-sm-left">
                <Col sm={3}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`mailto:${EMAIL_CONTACT}`}
                    className="card text-center mb-sm-0 mb-2  d-inline-block"
                  >
                    <CardBody
                      className={`p-3 display-3 btn-inner--icon text-primary ${classes.icon}`}
                    >
                      <i className="ni ni-email-83" />
                    </CardBody>
                  </a>
                </Col>
                <Col sm={9}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`mailto:${EMAIL_CONTACT}`}
                    className="h3 text-white mb-sm-0"
                  >
                    {EMAIL_CONTACT}
                  </a>
                </Col>
              </Row>
              <Row className="mt-4 align-items-center text-center text-sm-left">
                <Col sm={3}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`tel:${PHONE}`}
                    className="card text-center mb-sm-0 mb-2 d-inline-block"
                  >
                    <CardBody
                      className={`p-3 display-3 btn-inner--icon text-primary ${classes.icon}`}
                    >
                      <i className="fas fa-phone" />
                    </CardBody>
                  </a>
                </Col>
                <Col sm={9}>
                  <a href={`tel:${PHONE}`} className="h3 text-white mb-1">
                    {PHONE}&nbsp;
                    <small className="text-uppercase">(phone)</small>
                  </a>
                  <p className="h3 text-white mb-0">
                    {FAX} <small className="text-uppercase">(fax)</small>
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>
    );
  }
}

export default ContactUs;
