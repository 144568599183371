import { connect } from 'react-redux';

import { getList, getListAdmin } from '../../redux/lang/actions';

import Admin from './Admin';

export default connect(null, {
  getLangList: getList,
  getLangListAdmin: getListAdmin,
})(Admin);
