import { connect } from 'react-redux';

import MaterialsPage from './MaterialsPage';

const selector = null;

const actions = {};

export { MaterialsPage };

export default connect(selector, actions)(MaterialsPage);
