import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getListAdmin, remove } from '../../../redux/business-weekly/actions';
import {
  loading,
  listAdmin,
  listAdminCount,
} from '../../../redux/business-weekly/selectors';
import { list, defaultLang } from '../../../redux/lang/selectors';
import BusinessWeeklyListPage from './BusinessWeeklyListPage';

const selector = createStructuredSelector({
  loading,
  list: listAdmin,
  allCount: listAdminCount,
  langList: list,
  defaultLang,
});

const actions = {
  getListAdmin,
  remove,
};

export { BusinessWeeklyListPage };

export default connect(selector, actions)(BusinessWeeklyListPage);
