import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from '../../../utils';

import { createStructuredSelector } from 'reselect';

import {
  launchDataMigration,
  launchAudioHide,
  launchSefariaMigration,
  launchSefariaTorahMigration,
  launchTagsMigration,
  launchSefariaProphetsMigration,
  launchSefariaWritingsMigration,
  launchSefariaShulchanMigration,
  changeGlobalSettings,
  getGlobalSettings,
} from '../../../redux/settings/actions';
import {
  loading,
  processing,
  globalSettings,
} from '../../../redux/settings/selectors';
import { list, defaultLang } from '../../../redux/lang/selectors';
import SettingsPage from './SettingsPage';

const selector = createStructuredSelector({
  loading,
  processing,
  langList: list,
  defaultLang,
  globalSettings,
  initialValues: state => globalSettings(state),
});

const actions = {
  launchDataMigration,
  launchAudioHide,
  launchSefariaMigration,
  launchTagsMigration,
  launchSefariaTorahMigration,
  launchSefariaProphetsMigration,
  launchSefariaWritingsMigration,
  launchSefariaShulchanMigration,
  changeGlobalSettings,
  getGlobalSettings,
};

const reduxConnect = connect(selector, actions);

const formConnect = reduxForm({
  form: 'globalSettings',
  enableReinitialize: true,
});

export default compose(reduxConnect, formConnect)(SettingsPage);
