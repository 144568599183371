import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getListsPage,
  sendCustomEmail,
  scheduleCustomEmail,
  getCustomEmailsList,
  removeCustomEmail,
  getListLimit,
  prepareCustomEmail,
} from '../../../redux/customEmail/actions';
import { alert } from '../../../redux/notify/actions';

import { listAdmin, defaultLang } from '../../../redux/lang/selectors';
import {
  sendEmailStatus,
  current,
  customEmailsList,
  listsPage,
  listLimit,
  listCount,
  loading,
} from '../../../redux/customEmail/selectors';
import CustomEmailPage from './CustomEmailPage';

const selector = createStructuredSelector({
  langList: listAdmin,
  defaultLang,
  sendEmailStatus,
  current,
  customEmailsList,
  listsPage,
  listLimit,
  listCount,
  loading,
});

const actions = {
  getListsPage,
  scheduleCustomEmail,
  sendCustomEmail,
  alert,
  getCustomEmailsList,
  removeCustomEmail,
  getListLimit,
  prepareCustomEmail,
};

export default connect(selector, actions)(CustomEmailPage);
