import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';

import { compose } from '../../utils';
import { defaultLang, listAdmin } from '../../redux/lang/selectors';
import { saving } from '../../redux/users/selectors';
import { addSubscriber } from '../../redux/users/actions';

import SubscribeFormAdmin from './SubscribeFormAdmin';

const reduxConect = connect(
  createStructuredSelector({ saving, defaultLang, langList: listAdmin }),
  { onSubmit: addSubscriber },
);

const formConect = reduxForm({
  form: 'subscribeAdmin',
});

export default compose(reduxConect, formConect)(SubscribeFormAdmin);
