import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { listAdmin as categoryTypes } from '../../../../../redux/category-type/selectors';
import Categories from './Categories';

const selector = createStructuredSelector({
  categoryTypes,
});

const actions = {};

export default connect(selector, actions)(Categories);
