import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import RelatedDocuments from './RelatedDocuments';

import { recent } from '../../redux/document/selectors';
import { getRecent } from '../../redux/document/actions';

const selector = createStructuredSelector({
  recent,
});

export default connect(selector, { getRecent })(RelatedDocuments);
