import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { options } from '../../redux/notify/selectors';
import { reset } from '../../redux/notify/actions';
import { pathname } from '../../redux/router/selectors';
import { loadProfile, logout } from '../../redux/profile/actions';
import { user } from '../../redux/profile/selectors';
import { getList, getListSubscribe } from '../../redux/lang/actions';

import RootLayout from './RootLayout';

const selector = createStructuredSelector({
  notifyOptions: options,
  pathname,
  user,
});

const actions = {
  init: loadProfile,
  logout,
  resetNotify: reset,
  getLangList: getList,
  getLangListSubscribe: getListSubscribe,
};

export { RootLayout };

export default connect(selector, actions)(RootLayout);
