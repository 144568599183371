import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ShulchanAruchPage from './ShulchanAruchPage';
import { lang } from '../../redux/lang/selectors';

const selector = createStructuredSelector({
  lang,
});

const actions = {};

export { ShulchanAruchPage };

export default connect(selector, actions)(ShulchanAruchPage);
