import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import RelatedPublications from './RelatedPublications';

import { lang, defaultLang } from '../../redux/lang/selectors';
import { recent } from '../../redux/article/selectors';
import { getRecent } from '../../redux/article/actions';

const selector = createStructuredSelector({
  recent,
  lang,
  defaultLang,
});

export default connect(selector, { getRecent })(RelatedPublications);
