import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  getListAdmin,
  remove,
} from '../../../../../redux/materials-category-type/actions';
import {
  loading,
  listAdmin,
  materialsModalOpened,
} from '../../../../../redux/materials-category-type/selectors';
import { list, defaultLang } from '../../../../../redux/lang/selectors';
import Relations from './Relations';

const selector = createStructuredSelector({
  loading,
  relations: listAdmin,
  langList: list,
  defaultLang,
  materialsModalOpened,
});

const actions = {
  getListAdmin,
  remove,
};

export default connect(selector, actions)(Relations);
