import React from 'react';

import LocalizedLink from '../LocalizedLink';
import { Row, Col, UncontrolledTooltip, Button } from 'reactstrap';
import classnames from 'classnames';

import classes from './BannerBuilder.module.scss';
import { IMG_URL } from '../../constants/files';

class BannerItem extends React.Component {
  renderTooltip(key) {
    const { id, edit } = this.props;
    return (
      <UncontrolledTooltip
        autohide={false}
        hideArrow
        placement="left"
        target={`${key}-${id}`}
        innerClassName="bg-transparent"
        delay={{ show: 0, hide: 300 }}
        offset={0}
      >
        <Button onClick={() => edit(id, key)} size="sm">
          <i className="fas fa-edit" />
        </Button>
      </UncontrolledTooltip>
    );
  }

  render() {
    const { id, item, langKeys = {}, lang } = this.props;
    const isHE = lang === 'he';

    return (
      <>
        <Row className="align-items-center">
          <Col
            lg="5"
            className={classnames('pl-md-8 pr-md-0 pl-5 pr-5 text-center', {
              'text-md-left': !isHE,
              'text-md-right': isHE,
            })}
            dir={`${isHE ? 'rtl' : 'ltr'}`}
          >
            <div className="pr-md-5 mb-4 mb-lg-0">
              <h2
                className="display-2 text-white font-weight-bold mb-4"
                id={`title-${id}`}
              >
                {langKeys.title || 'Title'}
                {this.renderTooltip('title')}
              </h2>
              <p id={`description-${id}`} className="text-white mb-4">
                {langKeys.description || 'Description'}
                {this.renderTooltip('description')}
              </p>
              <div className="mt-5">
                <Button
                  id={`btnLabel-${id}`}
                  className="btn btn-lg btn-secondary my-2"
                  to="/"
                >
                  {langKeys.btnLabel || 'Label'}
                </Button>
                {this.renderTooltip('btnLabel')}
              </div>
            </div>
          </Col>
          <Col lg="7">
            {item.img ? (
              <div
                className={classes.image}
                style={{ backgroundImage: `url(${IMG_URL}${item.img})` }}
              />
            ) : (
              <div
                className={`display-3 text-center text-white bg-translucent-default d-flex justify-content-center align-items-center ${classes.image}`}
              >
                No image
              </div>
            )}
          </Col>
        </Row>
        <Row className={`text-center ${classes.caption}`}>
          <LocalizedLink id={`caption-${id}`} to={item.link} className="col-9">
            <span className="display-4 text-default">
              {langKeys.caption || 'Caption'}
            </span>
          </LocalizedLink>
          {this.renderTooltip('caption')}
          <LocalizedLink
            id={`link-${id}`}
            to={item.link}
            className="col-3 display-2 text-white font-weight-300 bg-default"
          >
            <i className="ni ni-bold-right" />
          </LocalizedLink>
          {this.renderTooltip('link')}
        </Row>
      </>
    );
  }
}

export default BannerItem;
