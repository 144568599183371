import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { compose } from '../../../utils';
import { DragSource, DropTarget } from 'react-dnd';
import { Button, Col, Row } from 'reactstrap';
import classes from '../BannerBuilder.module.scss';
import { IMG_URL } from '../../../constants/files';

const ItemTypes = {
  BannerCard: 'bannerCard',
};
const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      order: props.order,
      index: props.index,
    };
  },
};

const cardTarget = {
  hover: (props, monitor, component) => {
    if (!component) {
      return null;
    }
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }

    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

    const clientOffset = monitor.getClientOffset();
    const hoverClientX = clientOffset.x - hoverBoundingRect.left;
    if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
      return;
    }
    if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
      return;
    }
    props.moveCard(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

const DropTargetConnect = DropTarget(
  ItemTypes.BannerCard,
  cardTarget,
  connect => ({
    connectDropTarget: connect.dropTarget(),
  }),
);

const DrugSourceConnect = DragSource(ItemTypes.BannerCard, cardSource, collect);

class BannerCard extends Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
  };
  render() {
    const {
      img,
      langKeys = {},
      key,
      isDragging,
      connectDragSource,
      connectDropTarget,
    } = this.props;
    const opacity = isDragging ? 0.3 : 1;

    return (
      connectDragSource &&
      connectDropTarget &&
      connectDragSource(
        connectDropTarget(
          <div
            key={key}
            className={classnames(classes.bannerCard, {
              [classes.dragging]: isDragging,
            })}
            style={{ opacity }}
          >
            {isDragging ? (
              <div className={classes.dropHere}>Drop here</div>
            ) : (
              <Row noGutters className="align-items-center w-100">
                <Col
                  sm="5"
                  className="pl-md-2 pr-md-0 pl-1 pr-1 text-center text-md-left"
                >
                  <div className="pr-md-2 mb-2">
                    <p className="text-white mb-2">
                      {langKeys.title || 'Title'}
                    </p>
                    <span className="text-white">
                      {langKeys.description || 'Description'}
                    </span>
                    <div className="mt-4">
                      <Button size="sm" className="btn btn-secondary">
                        {langKeys.btnLabel || 'Label'}
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col sm="7">
                  {img ? (
                    <div
                      className={classes.image}
                      style={{
                        backgroundImage: `url(${IMG_URL}${img})`,
                      }}
                    />
                  ) : (
                    <div
                      className={`h5 text-center text-white bg-translucent-default d-flex justify-content-center align-items-center ${classes.image}`}
                    >
                      No image
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </div>,
        ),
      )
    );
  }
}
export default compose(DropTargetConnect, DrugSourceConnect)(BannerCard);
