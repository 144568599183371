import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  getItemAdmin,
  add,
  update,
  remove,
} from '../../../redux/submission/actions';
import {
  loading,
  saving,
  itemAdmin,
} from '../../../redux/submission/selectors';
import { list, defaultLang } from '../../../redux/lang/selectors';
import SubmissionItemPage from './SubmissionItemPage';

const selector = createStructuredSelector({
  loading,
  saving,
  submission: itemAdmin,
  langList: list,
  defaultLang,
});

const actions = {
  fetch: getItemAdmin,
  add,
  update,
  remove,
};

export { SubmissionItemPage };

export default connect(selector, actions)(SubmissionItemPage);
