import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import KuntraisimPage from './KuntraisimPage';
import { lang } from '../../redux/lang/selectors';

const selector = createStructuredSelector({
  lang,
});

const actions = {};

export { KuntraisimPage };

export default connect(selector, actions)(KuntraisimPage);
