import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { compose } from '../../utils';
import { donate } from '../../redux/users/actions';
import { donationLoading } from '../../redux/users/selectors';
import { withRouter } from 'react-router-dom';
import DonationForm from './DonationForm';

const selector = createStructuredSelector({
  donationLoading,
});

const actions = { onSubmit: donate };

const reduxConnect = connect(selector, actions);

const formConnect = reduxForm({
  form: 'donate',
});

export default withRouter(compose(reduxConnect, formConnect)(DonationForm));
