import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { formValueSelector, reduxForm } from 'redux-form';

import { compose } from '../../utils';
import { defaultLang, listAdmin } from '../../redux/lang/selectors';
import { saving } from '../../redux/users/selectors';
import {
  recent,
  hebcal,
  hebcalLoading,
  list,
} from '../../redux/business-weekly/selectors';
import {
  getList,
  getRecent,
  getHebcal,
} from '../../redux/business-weekly/actions';

import { alert } from '../../redux/notify/actions';

import SendSubscriptionForm from './SendSubscriptionForm';

const formConect = reduxForm({
  form: 'sendSubscription',
  enableReinitialize: true,
});

const sendSubscriptionValues = formValueSelector('sendSubscription');

const reduxConect = connect(
  createStructuredSelector({
    sending: saving,
    hebcalLoading,
    defaultLang,
    langList: listAdmin,
    recent,
    list,
    hebcal,
    sbjValues: state => sendSubscriptionValues(state, 'subject'),
    selectedBWId: state => sendSubscriptionValues(state, 'bwId'),
    selectedLang: state => sendSubscriptionValues(state, 'lang'),
    emailDate: state => sendSubscriptionValues(state, 'emailDate'),
    date: state => sendSubscriptionValues(state, 'date'),
  }),
  { getList, getRecent, getHebcal, alert },
);

export default compose(reduxConect, formConect)(SendSubscriptionForm);
