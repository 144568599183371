import React, { Component } from 'react';
import LocalizedLink from '../../components/LocalizedLink';

import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Row,
  CardBody,
} from 'reactstrap';
import { ROUTES } from '../../constants/routes';
import { LINK_LABELS } from '../../constants/labels';
import classes from '../../components/ContactUs/ContactUs.module.scss';
import { EMAIL_HALACHA, PHONE } from '../../constants/contactInfo';

export default class HalachicGuidancePage extends Component {
  render() {
    return (
      <>
        <section className="bg-gradient-info py-5">
          <Container>
            <Breadcrumb listClassName="breadcrumb-dark breadcrumb-links pb-2">
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.HOME}>
                  {LINK_LABELS.HOME.EN}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem aria-current="page" className="active">
                {LINK_LABELS.HALACHIC_GUIDANCE.EN}
              </BreadcrumbItem>
            </Breadcrumb>
          </Container>
        </section>
        <section className="py-6">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <h2 className="display-3">
                  {LINK_LABELS.HALACHIC_GUIDANCE.EN}
                </h2>
                <p>
                  Would you like specific guidance that pertains to your
                  business?
                </p>
                <p>An issue or dispute you’d like resolved quickly?</p>
                <p>Meet with Harav Chaim Kohn or your local BHI dayan.</p>
                <p>
                  <LocalizedLink to={ROUTES.CONTACT_US}>
                    Schedule an appointment
                  </LocalizedLink>
                </p>
              </Col>
              <Col xs={12} md={6}>
                <h2 className="display-3 mt-6 mt-md-0">
                  {LINK_LABELS.HOTLINE.EN}
                </h2>
                <p>Call or email a BHI rabbi with any monetary question.</p>
                <Row className="mt-4 align-items-center text-center text-sm-left">
                  <Col sm={3}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`mailto:${EMAIL_HALACHA}`}
                      className="card text-center mb-sm-0 mb-2  d-inline-block"
                    >
                      <CardBody
                        className={`p-3 display-3 btn-inner--icon text-primary ${classes.icon}`}
                      >
                        <i className="ni ni-email-83" />
                      </CardBody>
                    </a>
                  </Col>
                  <Col sm={9}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`mailto:${EMAIL_HALACHA}`}
                      className="h3 text-default mb-sm-0"
                    >
                      {EMAIL_HALACHA}
                    </a>
                  </Col>
                </Row>
                <Row className="mt-4 mb-4 align-items-center text-center text-sm-left">
                  <Col sm={3}>
                    <a
                      href={`tel:${PHONE}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="card text-center mb-sm-0 mb-2 d-inline-block"
                    >
                      <CardBody
                        className={`p-3 display-3 btn-inner--icon text-primary ${classes.icon}`}
                      >
                        <i className="fas fa-phone" />
                      </CardBody>
                    </a>
                  </Col>
                  <Col sm={9}>
                    <a href={`tel:${PHONE}`} className="h3 text-default mb-1">
                      {PHONE}
                    </a>
                  </Col>
                </Row>
                <p>
                  <LocalizedLink to={ROUTES.HOTLINE_FEEDBACK}>
                    Click here to read what others have said about the hotline
                  </LocalizedLink>
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
