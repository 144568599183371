import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { lang } from '../../redux/lang/selectors';
import { all as list, allCount, loading } from '../../redux/article/selectors';
import { list as getList } from '../../redux/article/actions';

import ArticlesPage from './ArticlesPage';

const selector = createStructuredSelector({
  lang,
  list,
  allCount,
  loading,
});

const actions = { getList };

export { ArticlesPage };

export default connect(selector, actions)(ArticlesPage);
