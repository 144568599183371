import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { resetHebcal } from '../../../redux/business-weekly/actions';
import {
  subscribe,
  getSubscribers,
  sendWeekly,
  scheduleWeekly,
  setSubscribed,
  exportSubscribers,
  importSubscribers,
} from '../../../redux/users/actions';
import {
  loading,
  subscribersList,
  subscribersCount,
  saving,
  savingSubscriber,
} from '../../../redux/users/selectors';
import { listAdmin, defaultLang } from '../../../redux/lang/selectors';
import SubscribersPage from './SubscribersPage';

const selector = createStructuredSelector({
  loading,
  saving,
  savingSubscriber,
  subscribersList,
  subscribersCount,
  langList: listAdmin,
  defaultLang,
});

const actions = {
  subscribe,
  getSubscribers,
  sendWeekly,
  scheduleWeekly,
  setSubscribed,
  export: exportSubscribers,
  import: importSubscribers,
  resetHebcal,
};

export default connect(selector, actions)(SubscribersPage);
