import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ArbitrationPage from './ArbitrationPage';
import { lang } from '../../redux/lang/selectors';

const selector = createStructuredSelector({
  lang,
});

const actions = {};

export { ArbitrationPage };

export default connect(selector, actions)(ArbitrationPage);
