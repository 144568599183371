import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { recent } from '../../../../redux/business-weekly/selectors';
import { defaultLang } from '../../../../redux/lang/selectors';
import SendSubscriptionModal from './SendSubscriptionModal';

const selector = createStructuredSelector({
  recent,
  defaultLang,
});

const actions = {};

export default connect(selector, actions)(SendSubscriptionModal);
