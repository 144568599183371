import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import BusinessWeekly from './BusinessWeekly';

import { lang } from '../../../../redux/lang/selectors';
import { recent } from '../../../../redux/business-weekly/selectors';
import { getRecent } from '../../../../redux/business-weekly/actions';

const selector = createStructuredSelector({
  recent,
  selectedLang: lang,
});

export default connect(selector, { getRecent })(BusinessWeekly);
