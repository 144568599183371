import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import AudioPage from './AudioPage';

import { lang } from '../../redux/lang/selectors';
import { audio, loading } from '../../redux/audio/selectors';
import { getItem } from '../../redux/audio/actions';
import { showRelated } from '../../redux/settings/selectors';

const selector = createStructuredSelector({
  audio,
  loading,
  lang,
  showRelated,
});

const actions = { getItem };

export { AudioPage };

export default connect(selector, actions)(AudioPage);
