import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import SearchGeneral from './SearchGeneral';

import { lang } from '../../redux/lang/selectors';
import { results, searchedStr, loading } from '../../redux/search/selectors';

const selector = createStructuredSelector({
  loading,
  searchedStr,
  results,
  lang,
});

export default connect(selector, {})(SearchGeneral);
