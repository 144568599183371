import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import CategoryTypeModal from '../CategoryTypeModal';

import styles from '../../Categories.module.scss';

class TypeList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      typeToEdit: {},
      fromProps: { saving: false },
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.editType = this.editType.bind(this);
    this.remove = this.remove.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { categoryTypeModalOpened } = nextProps;
    const newState = {
      fromProps: {
        saving: state.fromProps.saving,
        categoryTypeModalOpened: state.fromProps.categoryTypeModalOpened,
      },
    };

    if (categoryTypeModalOpened !== state.fromProps.categoryTypeModalOpened) {
      newState.fromProps.categoryTypeModalOpened = categoryTypeModalOpened;
    }

    return newState;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fromProps: { categoryTypeModalOpened },
    } = this.state;
    if (
      prevState.fromProps.categoryTypeModalOpened &&
      !categoryTypeModalOpened
    ) {
      this.toggleModal();
    }
  }

  remove(id) {
    const yes = window.confirm(
      'Are you sure you want delete this category type?',
    );
    if (!yes) return;
    const { remove } = this.props;
    if (id) {
      remove(id);
    }
  }

  toggleModal(open = false) {
    this.setState({ modalIsOpen: open });
    if (!open) {
      this.setState({ typeToEdit: {} });
    }
  }

  editType(typeToEdit) {
    const { getItemAdmin } = this.props;
    if (typeToEdit.id) {
      getItemAdmin(typeToEdit.id);
    }
    this.setState({ typeToEdit });
    this.toggleModal(true);
  }

  renderType(item) {
    return (
      <div key={`type-${item.id}`} className="position-relative">
        {item.parentId && (
          <div
            className={`position-absolute border-bottom left--4 ${styles.hLine}`}
          />
        )}
        <Card className={styles.card}>
          <CardBody className="p-4">
            {item.label}

            <Button
              className="btn btn-icon btn-secondary btn-sm ml-4"
              type="button"
              onClick={() => this.editType(item)}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-settings-gear-65" />
              </span>
            </Button>

            <Button
              className="btn btn-icon btn-danger btn-sm"
              type="button"
              disabled={item.categoryTypes}
              onClick={() => this.remove(item.id)}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-fat-remove" />
              </span>
            </Button>
          </CardBody>
        </Card>
        {item.categoryTypes && (
          <div className="ml-4 pl-4 pt-0 mb-0 position-relative">
            <div
              className={`position-absolute border-left left-0 h-100 ${styles.vLine}`}
            />
            {item.categoryTypes.map(item => this.renderType(item))}
          </div>
        )}
      </div>
    );
  }

  render() {
    const { typeList, defaultLang, itemAdmin } = this.props;
    const { modalIsOpen, typeToEdit } = this.state;

    return (
      <>
        <Container fluid className="py-4">
          <Row className="mb-4">
            <Col>
              <p className="display-4">Category types</p>
            </Col>
            <Col className="text-right">
              <Button
                color="primary"
                onClick={() => this.toggleModal(true)}
                className="btn btn-icon"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-fat-add" />
                </span>
                <span className="btn-inner--text">New type</span>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>{typeList.map(item => this.renderType(item))}</Col>
          </Row>
        </Container>

        <CategoryTypeModal
          isOpened={modalIsOpen}
          defaultLang={defaultLang}
          initialValues={
            typeToEdit.id
              ? { ...itemAdmin, categoryTypes: typeToEdit.categoryTypes || [] }
              : typeToEdit
          }
          close={this.toggleModal}
        />
      </>
    );
  }
}

export default TypeList;
