import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm } from 'redux-form';

import { compose } from '../../utils';
import { loading } from '../../redux/profile/selectors';
import { login } from '../../redux/profile/actions';

import LoginForm from './LoginForm';

const reduxConect = connect(createStructuredSelector({ loading }), {
  onSubmit: login,
});

const formConect = reduxForm({
  form: 'login',
});

export { LoginForm };

export default compose(reduxConect, formConect)(LoginForm);
