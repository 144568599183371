import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import SearchForm from './SearchFilters';
import { search } from '../../redux/search/actions';
import { searchedStr } from '../../redux/search/selectors';

const selector = createStructuredSelector({
  searchedStr,
});

const actions = { search };

export default connect(selector, actions)(SearchForm);
