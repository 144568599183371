import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { list, lang, defaultLang } from '../../redux/lang/selectors';
import { set } from '../../redux/lang/actions';

import LocaleSelector from './LocaleSelector';

const selector = createStructuredSelector({ list, lang, defaultLang });

export default connect(selector, { set })(LocaleSelector);
